import { state } from ":mods";
import { createEffect, createSignal, For, Index } from "solid-js";
import { DragNDropViewProps } from "../model";

export function DragNDrop<T extends any[], U extends JSX.Element>(props: DragNDropViewProps<T, U>) {
  const [items, setItems] = createSignal(undefined as any[]);
  const [draggedIndex, setDraggedIndex] = createSignal(null);
  let parent = undefined;
  createEffect(() => {
    setItems(props.each as any);
  });
  const handleDragStart = (
    event: DragEvent & {
      currentTarget: HTMLDivElement;
      target: Element;
    },
    index
  ) => {
    console.log("drag started with ", event, index);
    parent = event.target.parentNode;
    setDraggedIndex(index);

    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (
    event: DragEvent & {
      currentTarget: HTMLDivElement;
      target: Element;
    },
    index
  ) => {
    event.preventDefault();
    if (index !== draggedIndex() && parent) {
      console.log("drag over ", event);
      const updatedItems = [...(items() as any)];
      const [draggedItem] = updatedItems.splice(draggedIndex(), 1);
      updatedItems.splice(index, 0, draggedItem);
      setItems(updatedItems as any);
      if (props.onSwap) {
        props.onSwap(updatedItems as any, draggedIndex(), index);
      }
      setDraggedIndex(index);
    }
  };

  const handleDrop = (event) => {
    parent = undefined;
    event.preventDefault();
    setDraggedIndex(null);
  };

  return (
    <>
      <For each={items()}>
        {(item, index) => {
          const $dragged = state.createMemo(() => ({
            started: draggedIndex() !== null,
            itemNotDragged: draggedIndex() !== null && draggedIndex() !== index(),
            itemDragged: draggedIndex() === index(),
          }));

          return props.children(
            item,
            index,
            $dragged,
            {
              draggable: true,
              onDragStart: (event) => handleDragStart(event, index()),
            },
            {
              onDragOver: (event) => handleDragOver(event, index()),
              onDrop: handleDrop,
            }
          );
        }}
      </For>
    </>
  );
}
